
  import { Component, Watch } from 'vue-property-decorator'
  import { plainToInstance } from 'class-transformer'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { Form } from '@/entities/public/Resource/metadata'
  import { fixAmountFormatted, isValidNumber, stringifySafe } from '@/utils/general'

  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'

  import { GForm } from '@/components/forms/GForm'
  import { PublishPlatform, SaleOrder, Stock } from '@/entities/sales'
  import GCostField from '@/components/core/input/GCostField.vue'
  import AutoResume from '@/components/forms/AutoResume.vue'
  import { Deal } from '@/entities/crm'
  import { Debounce } from '@/utils/decorators'
  import InspectionPDF from '@/components/forms/inspection/report/InspectionPDF.vue'

@Component({
  components: { InspectionPDF, AutoResume, GCostField, GRadioButton, GFiles, LinkedPerson, BaseForm },
  methods: { fixAmountFormatted },
  computed: {},
})
  export default class SupervisorStockPriceForm extends GForm {
  stock: Stock = plainToInstance(Stock, {})
  deal = null
  carLoaded = false
  message = ''
  processInspectionId = null
  showDetail = false
  showFields = false
  disabledPrice = false
  reserveAlert = false
  sellingProcess = false
  disabledStockPrice = false
  saleOrder: SaleOrder | null = null
  categoryComponentMaintenance = null
  categoryComponentIdentification = null
  priceList = null
  hasFiles = false
  link = null
  errorMessage = {
    mileage: '',
  }

  publishPlatforms: PublishPlatform[] = []

  title = ''

  formData = {
    priceList: null,
    discount: null,
    renewalBonus: null,
    financingBonus: null,
    commentForSalesTeam: '',
    commentForPublication: '',
  }

  fields = {
    button1: {
      label: '',
      link: '',
    },
    button2: {
      label: '',
      link: '',
    },
  }

  metadata = {}
  metadataCollection = {}

  async mounted () {
    await this.setMetadata()
    const { uid, id, title, metadataCollection } = this
    const enablementId = uid || id

    if (isValidNumber(enablementId)) {
      await this.getStockInfo(enablementId)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadataCollection, title, Boolean(enablementId))
    }

    await this.getPublishPlatforms()

    this.displayDetail()
  }

  async setMetadata () {
    const { metadata } = this.getForm('Stock', 'supervisor_stock_price')
    const { form, fields } = metadata as Form
    this.title = form.title
    this.metadataCollection = metadata

    this.fields.button1.label = fields.button1.properties.label
    this.fields.button1.link = fields.button1.properties.url
    this.fields.button2.label = fields.button2.properties.label
    this.fields.button2.link = fields.button2.properties.url
  }

  async getPublishPlatforms () {
    const platforms = await this.fetchData({
      query: { name: 'find', model: 'Platform' },
      filter: {
        _and: [
          { name_platform_type: { _eq: 'publication' } },
          { active: { _eq: true } },
        ],
      },
    })

    const stockPlatforms = this.stock.metadata?.publishPlatforms || []

    this.publishPlatforms = platforms
      .sort((prev, next) => {
        return prev.name < next.name ? -1 : 1
      })
      .map(portal => ({
        id: portal.id,
        name: portal.name,
        enabled: stockPlatforms.length ? stockPlatforms?.find(platform => platform.name === portal.name)?.enabled : true,
      }))
  }

  async getStockInfo (id) {
    this.stock = await this.fetchData({
      query: { name: 'fetch', model: 'Stock', params: { id } },
      force: true,
    })

    this.deal = plainToInstance(Deal, { id: this.stock.viewDetails.idDeal })
    this.deal.auto = this.stock.auto
    const appraisal = await this.fetchData({
      query: { name: 'find', model: 'Appraisal' },
      filter: { id_deal: { _eq: this.stock.viewDetails.idDeal } },
      force: true,
    })
    this.link = appraisal?.[0]?.link

    this.stock.transferCost = await this.calculateTransfer(id)

    if (this.stock.auto?.generation?.id) {
      const generation = await this.fetchData({
        query: { name: 'find', model: 'Generation' },
        filter: { id: { _eq: this.stock.auto.generation.id } },
        force: true,
      })
      this.stock.auto.generation = generation?.[0]
    }

    this.processInspectionId = (await this.fetchData({
      query: { name: 'find', model: 'StockViewDetails' },
      filter: { id_stock: { _eq: id } },
    }))[0].idInspection

    const prices = await this.fetchData({
      query: { name: 'find', model: 'Price' },
      filter: { _and: [{ id_stock: { _eq: id } }, { expiration: { _is_null: true } }] },
      force: true,
    })

    const sales = await this.fetchData({
      query: { name: 'find', model: 'SaleOrder' },
      filter: {
        deal: { id_stock: { _eq: id } },
      },
      force: true,
    })

    const status = ['to_contract', 'legal_review', 'approved']

    const saleOrder = sales.find(sale => status.includes(sale.status.status.name))
    this.saleOrder = saleOrder
    this.disabledStockPrice = Boolean(saleOrder?.id)

    this.priceList = prices?.[0]?.amount
    await this.checkValidations(id)

    await this.getStockFiles(id)
  }

  async checkValidations (id) {
    const exist = await this.fetchData({
      query: { name: 'find', model: 'Stock' },
      filter: {
        _and: [
          {
            deals: {
              _and: [
                {
                  id_closing_reason: {
                    _is_null: true,
                  },
                },
                {
                  payments: {
                    payment_type: {
                      name: {
                        _eq: 'financing',
                      },
                    },
                  },
                },
              ],
            },
          },
          {
            id: {
              _eq: id,
            },
          },
        ],
      },
      force: true,
    })

    this.disabledPrice = Boolean(exist.length)

    const reserveExist = await this.fetchData({
      query: { name: 'find', model: 'Stock' },
      filter: {
        _and: [
          {
            deals: {
              _and: [
                {
                  id_closing_reason: {
                    _is_null: true,
                  },
                },
                {
                  sale_orders: {
                    reserve: {
                      id_closing_reason: {
                        _is_null: true,
                      },
                    },
                  },
                },
              ],
            },
          },
          {
            id: {
              _eq: id,
            },
          },
        ],
      },
      force: true,
    })
    this.reserveAlert = Boolean(reserveExist.length)

    const sellingProcess = await this.fetchData({
      query: { name: 'find', model: 'Stock' },
      filter: {
        _and: [
          {
            deals: {
              _and: [
                {
                  id_closing_reason: { _is_null: true },
                },
                {
                  sale_orders: {
                    _and: [
                      {
                        id_closing_reason: { _is_null: true },
                      },
                      {
                        status: {
                          status: {
                            name: {
                              _neq: 'pending',
                            },
                          },
                        },
                      },
                    ],
                  },
                }],
            },
          },
          {
            id: { _eq: id },
          }],
      },
      force: true,
    })
    this.sellingProcess = Boolean(sellingProcess?.length)
  }

  async getStockFiles (uid) {
    const photos = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'stock' } } }, { file_type: { name: { _eq: 'photo' } } }] },
    })

    const fileParametersPhoto = photos.sort((a, b) => a.visibility?.form?.order - b?.visibility?.form?.order)
    const handleComponentChange = async parameter => {
      const photos = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: uid } },
            { id_file_parameter: { _eq: parameter.id } },
          ],
        },
        force: true,
      })

      if (photos.length) {
        this.hasFiles = true
      }
    }

    for (const parameter of [...fileParametersPhoto]) {
      await handleComponentChange(parameter)
    }
  }

  displayDetail () {
    const { stock, metadataCollection } = this

    this.metadata = {
      data: stock,
      metadata: metadataCollection,
    }

    this.showDetail = Boolean(stock.id)
  }

  async send () {
    if (!this.$refs.form.validate()) {
      await this.setFieldError()
      return
    }
    this.loadingForm = true
    if (this.formData.priceList) {
      await this.sendPrices()
    }
    await this.sendMetadata()
    await this.publishStock(this.stock.id)
    this.loadingForm = false
    await this.$router.push({ path: '/datatable/supervisor/supervisor_stock' }).catch(() => {
    })
  }

  async sendMetadata () {
    const { publishPlatforms, stock, idEmployee } = this

    stock.metadata = {
      ...stock.metadata,
      publishPlatforms,
    }

    await this.pushData({
      model: 'Stock',
      fields: {
        id: stock.id,
        id_supervisor: idEmployee,
        metadata: stock.metadata,
      },
    })
  }

  async sendPrices () {
    const { formData, stock } = this

    const newPrice = await this.pushData({
      model: 'Price',
      fields: {
        id_stock: stock.id,
        amount: formData.priceList,
        margin: formData.discount,
        comment: formData.commentForPublication,
        post_comment: formData.commentForSalesTeam,
      },
    })
    if (formData.priceList && stock.status.isEnabling) {
      const avaliableStatus = (await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { status: { name: { _eq: 'available' } } },
        force: true,
      }))[0]

      this.pushData({
        model: 'Stock',
        fields: {
          id: stock.id,
          id_process_status: avaliableStatus.id,
        },
      })
    }

    const types = await this.fetchData({
      query: { name: 'find', model: 'BonusType' },
      force: true,
    })

    if (formData.renewalBonus) {
      await this.pushData({
        model: 'Bonus',
        fields: {
          id_price: newPrice.id,
          amount: formData.renewalBonus,
          id_bonus_type: types.find(type => type.name === 'renewal').id,
        },
      })
    }

    if (formData.financingBonus) {
      await this.pushData({
        model: 'Bonus',
        fields: {
          id_price: newPrice.id,
          amount: formData.financingBonus,
          id_bonus_type: types.find(type => type.name === 'financing').id,
        },
      })
    }

    await this.updateOtherSalesOrderItem(stock.id, formData.priceList)
  }

  async updateOtherSalesOrderItem (stockId, amount) {
    const salesOrderItems = await this.fetchData({
      query: { name: 'find', model: 'SaleOrderItem' },
      filter: {
        _and: [
          {
            sale_order: {
              _and: [
                { id_closing_reason: { _is_null: true } },
                { deal: { id_stock: { _eq: stockId } } },
              ],
            },
          },
          {
            item_type: { name: { _eq: 'product' } },
          },
        ],
      },
      force: true,
    })

    await Promise.all(salesOrderItems.map(async item => {
      await this.pushData({
        model: 'SaleOrderItem',
        fields: {
          id: item.id,
          amount,
        },
      })
    }))
  }

  displayFields () {
    const { formData: { priceList } } = this

    if (priceList?.length > 6 && isValidNumber(priceList)) {
      this.showFields = true
    }
  }

  async openAppraisalLink () {
    const { stock, link } = this

    if (link) {
      this.openLink(link)
      return
    }

    await this.buildLinkChileAutos(stock)
  }

  get change () {
    const { formData, showFields, carLoaded } = this

    return stringifySafe([formData, showFields, carLoaded])
  }

  get officialSite () {
    const { stock } = this

    return stock?.auto?.officialSite || ''
  }

  get hasPrice () {
    const { priceList } = this.formData

    return Boolean(priceList)
  }

  get filesSet () {
    const { hasFiles } = this
    return hasFiles
  }

  get displayMessage () {
    const { hasPrice, filesSet } = this
    return hasPrice && filesSet
  }

  get disabledForm () {
    const { stock } = this

    return stock?.status?.isSold
  }

  @Debounce()
  photoLoaded () {
    this.carLoaded = !this.carLoaded
  }

  @Watch('formData.priceList', { immediate: true })
  onPriceListChange () {
    this.displayFields()
  }

  async ownAppraisal () {
    const { stock } = this

    const query = [
      {
        deal: {
          auto: {
            version_year: {
              version: {
                model: {
                  brand: {
                    name: {
                      _ilike: `%${stock.auto.version.version.model.brand.name}%`,
                    },
                  },
                },
              },
            },
          },
        },
      },
      {
        deal: {
          auto: {
            version_year: {
              version: {
                model: {
                  name: {
                    _ilike: `%${stock.auto.version.version.model.name}%`,
                  },
                },
              },
            },
          },
        },
      },
    ]

    const sortBy = 'auto.year'
    await this.redirectToPage('/datatable/supervisor/supervisor_appraisals', query, sortBy)
  }

  async ownSales () {
    const { stock } = this

    const query =
      [
        {
          deal: {
            auto: {
              version_year: {
                version: {
                  model: {
                    brand: {
                      name: {
                        _ilike: `%${stock.auto.version.version.model.brand.name}%`,
                      },
                    },
                  },
                },
              },
            },
          },
        },
        {
          deal: {
            auto: {
              version_year: {
                version: {
                  model: {
                    name: {
                      _ilike: `%${stock.auto.version.version.model.name}%`,
                    },
                  },
                },
              },
            },
          },
        },
      ]
    const sortBy = 'deal.auto.year'
    await this.redirectToPage('/datatable/supervisor/supervisor_sales', query, sortBy)
  }

  closeStock () {
    this.$router.push({ path: '/datatable/supervisor/supervisor_stock' }).catch(() => {
    })
  }

  async watchLeads () {
    const query = [
      {
        deals: {
          stock: {
            id: {
              _eq: this.stock.id,
            },
          },
        },
      },
    ]
    const sortBy = 'deal.auto.year'
    await this.redirectToPage('/datatable/supervisor/supervisor_leads', query, sortBy)
  }

  async openAuto () {
    const { stock } = this

    const autoId = stock?.auto?.id?.toString()

    await this.$router.push({
      name: 'generic-auto',
      params: { model: 'supervisor_stock_price', uid: null, id: autoId },
    })
    .catch(() => {
    })
  }
  }
